<template>
  <div class="center-1200 container">
    <div v-if="loading && !error" class="flex-row">
      <img src="/assets/img/loader.svg" alt="" class="auto-margin vertical-spacing-top" />
    </div>
    <div v-if="error">
      <p>{{ getError() }}</p>
      <button v-on:click="clearError()">Back</button>
    </div>
    <div v-if="offsiteForm && !error">
      <div class="flex-column">
        <h1 class="text-center">Welcome {{ offsiteForm.customer_name }}!</h1>
        <h6 class="vertical-spacing-top-half text-center">
          Thank you for enquiring at {{ offsiteForm.dealership.name }} about an appraisal for your vehicle.
        </h6>
      </div>
    </div>
    <div v-if="offsiteForm && offsiteForm.form">
      <p class="text-center">You have submitted this vehicle, we are currently appraising the request.</p>
      <p class="text-center">
        In the meantime, please upload some photos. The more photos you upload, the more accurate we can be. Please
        upload a minimum of 3 photos.
      </p>
      <p class="text-center">You can upload up to 10 photos. This link will availble for the next 30 minutes.</p>
      <label class="vertical-spacing-top-half">Photos:</label>
      <div class="flex-row">
        <p>Max file size, 3MB</p>
        <p :class="{ error: getFileSize() > 3 }">({{ getFileSize() }}/3MB Used)</p>
      </div>
      <div class="flex-row overflow-scroll">
        <img
          v-for="photo in general_photos"
          v-bind:key="photo.url"
          :src="photo.photo"
          alt=""
          style="max-height: 200px; max-width: 40%"
        />
      </div>
      <div class="flex-row">
        <input
          style="width: 80%"
          accept="image/jpeg"
          type="file"
          class="vertical-spacing-half input-field"
          id="file"
          ref="photo"
          name="photo"
          v-on:change="handleFileUpload($event)"
        />
        <button
          type="button"
          name="photo"
          class="flex-end vertical-spacing-half"
          title="Clear"
          v-on:click="handleFileClear"
        >
          X
        </button>
      </div>
      <primary-button
        v-if="photo"
        v-on:click="uploadPhoto()"
        class="vertical-spacing-top-half"
        :title="is_submitting ? 'Submitting...' : 'Submit Photo'"
        type="submit"
      />
    </div>
    <div v-if="!loading && !error && offsiteForm && !offsiteForm.form" class="flex-column">
      <p class="text-center">
        To get started, fill in the following details about your car and we will handle the rest.
      </p>
      <form @submit.prevent="submit" class="flex-column">
        <label>Registration Number</label>
        <input
          class="uppercase"
          required
          type="text"
          v-model="fulfilmentData.car_registration_number"
          placeholder="Rego"
        />

        <label class="vertical-spacing-top-half">Registration State</label>
        <select required v-model="fulfilmentData.car_registration_state">
          <option value="">-</option>
          <option value="SA">SA</option>
          <option value="NSW">NSW</option>
          <option value="VIC">VIC</option>
          <option value="QLD">QLD</option>
          <option value="WA">WA</option>
          <option value="NT">NT</option>
          <option value="TAS">TAS</option>
          <option value="ACT">ACT</option>
        </select>

        <label class="vertical-spacing-top-half">Mileage (in km)</label>
        <input required type="number" v-model="fulfilmentData.car_mileage" placeholder="Mileage" />

        <label class="vertical-spacing-top-half">Service History Quality</label>
        <select required v-model="fulfilmentData.service_history_quality">
          <option value="">-</option>
          <option value="0">Bad</option>
          <option value="1">Average</option>
          <option value="2">Good</option>
        </select>

        <label class="vertical-spacing-top-half">Build Date</label>
        <input required type="month" v-model="car_build_date_holder" />

        <label class="vertical-spacing-top-half">Finance requested on proposed new purchase</label>
        <div class="flex-row">
          <input
            required
            class="horizontal-spacing-right-quarter"
            type="radio"
            name="is_finance_requested"
            id="is_finance_requested"
            :value="true"
            v-model="fulfilmentData.is_finance_requested"
          />
          <label class="horizontal-spacing-right-half auto-margin-vertical" for="is_finance_requested">Yes</label>
          <input
            class="horizontal-spacing-right-quarter"
            type="radio"
            name="is_finance_requested"
            id="not_is_finance_requested"
            :value="false"
            v-model="fulfilmentData.is_finance_requested"
          />
          <label class="horizontal-spacing-right-half auto-margin-vertical" for="not_is_finance_requested">No</label>
        </div>

        <label class="vertical-spacing-top-half">Appraised vehicle currently encumbered</label>
        <div class="flex-row">
          <input
            required
            class="horizontal-spacing-right-quarter"
            type="radio"
            name="is_currently_encumbered"
            id="is_currently_encumbered"
            :value="true"
            v-model="fulfilmentData.is_currently_encumbered"
          />
          <label class="horizontal-spacing-right-half auto-margin-vertical" for="is_currently_encumbered">Yes</label>
          <input
            class="horizontal-spacing-right-quarter"
            type="radio"
            name="is_currently_encumbered"
            id="not_is_currently_encumbered"
            :value="false"
            v-model="fulfilmentData.is_currently_encumbered"
          />
          <label class="horizontal-spacing-right-half auto-margin-vertical" for="not_is_currently_encumbered">No</label>
        </div>
        <div class="flex-column" v-if="fulfilmentData.is_currently_encumbered">
          <label class="vertical-spacing-top-half" for="approx_payout">Approximate Payout In Dollars</label>
          <input type="number" id="approx_payout" v-model="fulfilmentData.approx_payout" placeholder="Approx Payout" />
          <label class="vertical-spacing-top-half" for="monthly_repayment">Target Repayment Monthly In Dollars</label>
          <input
            type="number"
            id="monthly_repayment"
            v-model="fulfilmentData.monthly_repayment"
            placeholder="Monthly Payout"
          />
          <label class="vertical-spacing-top-half" for="financier">Financier</label>
          <input type="text" id="financier" v-model="fulfilmentData.financier" placeholder="Financier" />
        </div>

        <label class="vertical-spacing-top-half"
          >I have read and acknowledged the Privacy Policy and Terms & Conditions and agree to my vehicle being
          remarketed.</label
        >
        <div class="flex-row">
          <input
            class="horizontal-spacing-right-quarter"
            type="radio"
            name="customer_remarketing_accepted"
            id="customer_remarketing_accepted"
            :value="true"
            v-model="fulfilmentData.customer_remarketing_accepted"
          />
          <label class="horizontal-spacing-right-half auto-margin-vertical" for="customer_remarketing_accepted"
            >Yes</label
          >
          <input
            class="horizontal-spacing-right-quarter"
            type="radio"
            name="not_customer_remarketing_accepted"
            id="not_customer_remarketing_accepted"
            :value="false"
            v-model="fulfilmentData.customer_remarketing_accepted"
          />
          <label class="horizontal-spacing-right-half auto-margin-vertical" for="not_customer_remarketing_accepted"
            >No</label
          >
        </div>
        <div class="flex-row">
          <a href="/privacy-policy/" target="”_blank”">Privacy Policy</a>
          <a class="horizontal-spacing-left-half" href="/terms-and-conditions/" target="”_blank”">Terms & Conditions</a>
        </div>
        <primary-button
          class="vertical-spacing-top-half"
          :title="is_submitting ? 'Submitting...' : 'Submit'"
          type="submit"
        />
      </form>
    </div>
  </div>
</template>

<script>
import { findOffsiteForm, fulfilOffsiteForm, fulfilOffsiteFormPhoto } from "../api/OffsiteForms.js";
import PrimaryButton from "../components/Buttons/PrimaryButton.vue";

export default {
  name: "OffsiteFormCustomer",
  components: { PrimaryButton },
  data: function () {
    return {
      loading: false,
      offsiteForm: null,
      error: null,
      car_build_date_holder: "",
      is_submitting: false,
      general_photos: [],
      photo: null,
      fulfilmentData: {
        uuid: "",
        car_registration_number: "",
        car_registration_state: "",
        car_mileage: null,
        car_build_date: "",
        service_history_quality: "",
        is_currently_encumbered: false,
        is_finance_requested: false,
        financier: "",
        approx_payout: null,
        monthly_repayment: null,
        customer_remarketing_accepted: false
      }
    };
  },
  methods: {
    submit: function () {
      if (this.is_submitting) {
        return;
      }
      this.is_submitting = true;
      fulfilOffsiteForm(Object.fromEntries(Object.entries(this.fulfilmentData).filter(([, v]) => v != null && v != "")))
        .then(response => {
          this.is_submitting = false;
          this.offsiteForm.form = response.url;
        })
        .catch(error => {
          this.is_submitting = false;
          this.error = error;
        });
    },
    handleFileUpload: function (event) {
      this.photo = event.target.files[0];
    },
    handleFileClear: function () {
      this.$refs["photo"].value = null;
      this.photo = null;
    },
    getFileSize: function () {
      if (!this.photo) {
        return 0;
      }
      return (this.photo.size / (1024 * 1024)).toFixed(1);
    },
    uploadPhoto: function () {
      if (this.getFileSize() > 3) {
        alert(
          "Photo file size too large. Try scaling them down. An easy way to do this on phones is to screenshot the photo."
        );
        return;
      }
      if (this.is_submitting) {
        return;
      }
      this.is_submitting = true;
      fulfilOffsiteFormPhoto(this.fulfilmentData.uuid, this.photo)
        .then(response => {
          setTimeout(() => {
            this.general_photos.push(response);
            this.handleFileClear();
            this.is_submitting = false;
          }, 2000);
        })
        .catch(error => {
          alert(error.response.data.detail);
          this.is_submitting = false;
        });
    },
    getError: function () {
      if (this.error) {
        if (this.error.response) {
          if (this.error.response.data?.error) {
            return this.error.response.data.error;
          } else if (this.error.response.data?.detail) {
            return this.error.response.data?.detail;
          } else if (typeof this.error.response.data === "object") {
            var errorMessage = "";
            for (const [key, value] of Object.entries(this.error.response.data)) {
              errorMessage += key;
              errorMessage += ": ";
              errorMessage += value;
              errorMessage += "\n";
            }
            return errorMessage;
          }
        }
        if (this.error.message) {
          return this.error.message;
        }
        return this.error;
      }
      if (this.errorDict) {
        if (this.errorDict.response?.data?.detail) {
          return this.errorDict.response?.data?.detail;
        } else if (this.errorDict.response?.data?.non_field_errors) {
          var error = "";
          this.errorDict.response?.data?.non_field_errors.forEach(error1 => {
            error += error1;
            error += " ";
          });
          return error;
        } else if (typeof this.errorDict === "string") {
          return this.errorDict;
        }
      }

      return "Something went wrong...";
    },
    clearError: function () {
      this.error = null;
      let photos = ["photo1", "photo2", "photo3", "photo4", "photo5"];
      photos.forEach(photoName => {
        delete this.fulfilmentData[photoName];
      });
    }
  },
  mounted: function () {
    this.loading = true;
    this.fulfilmentData.uuid = this.$route.params.uuid;
    findOffsiteForm(this.$route.params.uuid)
      .then(response => {
        this.loading = false;
        this.offsiteForm = response;
        this.general_photos = response.photos;
      })
      .catch(error => {
        this.loading = false;
        this.error = error;
      });
  },
  watch: {
    car_build_date_holder: function (newVal) {
      this.fulfilmentData.car_build_date = newVal + "-15";
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../scss/_variables.scss";
@import "../scss/base.scss";

.container {
  padding: $PADDING;
  margin-bottom: $PADDING;
}

input,
select {
  @extend .input-field;
}
</style>
